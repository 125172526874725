<script setup lang="ts">
import type { KeenSliderOptions } from "keen-slider";
import { toast } from "vue3-toastify";

import type { EventData, Promotion } from "@/types";

const { copy } = useClipboard();

const { t } = useT();
const { open } = useAppModals();

const sliderOptions: KeenSliderOptions = {
	loop: false,
	mode: "snap",
	slides: { perView: 4.1 },
	breakpoints: {
		"(max-width: 1440px)": {
			slides: {
				perView: "auto"
			}
		},
		"(max-width: 576px)": {
			slides: {
				origin: "center",
				perView: 1.15
			}
		}
	},
	created(slider) {
		setTimeout(() => {
			slider.update();
		}, 1500);
	}
};

const { isDesktop } = useDevice();
const router = useRouter();
const {
	public: { baseImageUrl }
} = useRuntimeConfig();

const sliderRef = ref();

const { handleOpen: handleMysteryBoxesOpen } = useMysteryBoxesController();

const {
	refresh: refreshPromotionsData,
	homePromotions,
	magicBoxPromotion,
	openCash,
	refreshHomeData
} = usePromotionsData();

const { data: bonusesData } = useBonusesData();
const { data: appInitData } = useAppInitData();
const loginGuard = useLoginGuard();
const { entriesSum, activeStatus, isSubscribed, tournamentData, durationLeft, coinsSum, handleSubscribe } = useRace();
const { currentTask, questData } = useQuestTask();
const { isSupercharged, isRegular, isActiveSuperchargedMode } = useMysteryBoxes();
const { isEventBox } = useEventsCollector();
const { seasonInitData } = useSeasonsData({ immediate: false });
const { prizeDropsData, prizeDropActive } = usePrizeDrop();
const { isFreeSpinQuest } = useFreeSpinQuest();
const { depositInfoData, durationLeft: depositDurationLeft } = useFunzcityDepositStreak();

const { data: scratchCardsData } = useScratchCard({
	immediate: !!appInitData.value?.scratchCardLottery?.isActive,
	redirectCb: () => sliderRef.value?.slider.update()
});

const { add } = useSockets();

const {
	type: rankStatusType,
	rankLeagueEnabled,
	handleUnlock
} = useRankedSystem({
	open
});
const { data: luckySpinData } = useGetLuckySpinData();
const {
	durationLeft: durationLeftStreak,
	isAvailable,
	levels,
	activeLevel,
	currentPrize,
	totalPrizes,
	isCompleted,
	isActiveDailyLogin,
	handleOpenFreeSpinGame
} = useDailyLogin();

const checkDepositStreakActive = (value: number) =>
	!!appInitData.value?.depositStreak?.isActive && value >= (depositInfoData.value?.minDepositValue ?? 0);

const handleMagicBoxClick = () => {
	loginGuard({
		success: () => {
			handleMysteryBoxesOpen();
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});
};

const handleVipClubClick = () => {
	loginGuard({
		success: () => {
			handleUnlock(() => {
				router.push("/vip-club/");
			});
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});
};

const handleRaceClick = () => {
	loginGuard({
		success: () => {
			if (!activeStatus.value) {
				router.push("/races/");
				return;
			}
			handleSubscribe({
				id: tournamentData.value?.data?.id || 0,
				isSub: isSubscribed.value
			});
			open("LazyOModalRacesGames", { isRaceMode: true });
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});
};

const handleFortuneWheelClick = () => {
	loginGuard({
		success: () => {
			if (luckySpinData.value?.wheelAvailable) {
				open("LazyOModalWheelFortuneWheel");
				return;
			}
			open("LazyOModalRacesGames");
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});
};

const handleInviteClick = (promotion: Promotion) => {
	loginGuard({
		success: () => {
			const referralUrl = promotion.data?.referralUrl || "";

			copy(referralUrl);
			toast.success(t("Link copied"), {
				containerId: "referralModal",
				icon: h("i", { class: "toast-icon icon-checked" }),
				closeButton: () => h("i", { class: "toast-icon icon-close" }),
				theme: toast.THEME.DARK,
				position: toast.POSITION.BOTTOM_RIGHT,
				transition: toast.TRANSITIONS.SLIDE,
				autoClose: 5000
			});
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});
};

const handleClick = (promotion: Promotion) => {
	loginGuard({
		success: () => {
			if (promotion.type === "quest") {
				return open("LazyOModalQuests");
			}
			if (promotion.type === "verification") {
				return router.push(preparedLink("game", "/#verify"));
			}
			if (promotion.type === "invite") {
				return handleInviteClick(promotion);
			}
			if (promotion.type === "inviteQualification") {
				window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
			}

			if (promotion?.data?.enabled) {
				dispatchGAEvent({ event: "click_button", location: "home", buttonName: "make_deposit" });
				window?.$store?.gaCash?.deposit?.({
					location: "home",
					step: "view_payments_window",
					activePackage: promotion?.data?.money
				});

				openCash(promotion);
			}
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});
};

const handlePromotionsRefresh = async () => {
	await refreshPromotionsData();
	await nextTick();
	sliderRef.value?.slider.update();
};

const getImgFullPath = (src: string) => {
	const isNuxtImg = src.includes("nuxt-img");
	return isNuxtImg ? src : `${baseImageUrl}${src}`;
};

const goToPromotionPage = () => {
	loginGuard({
		success: () => {
			router.push("/promotions/");
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});
};

const handleSeasonClick = () => {
	loginGuard({
		success: () => {
			if (!seasonInitData.value?.isAvailable) {
				window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
				return;
			}

			open("LazyOModalSeason");
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});
};

add("promotionsPage", ({ action }: EventData<Promotion>) => {
	if (action === "update") {
		refreshHomeData();
		refreshPromotionsData();
	}
});

watch(
	[homePromotions, bonusesData, scratchCardsData],
	async () => {
		await nextTick();
		sliderRef.value?.slider.update();
	},
	{ immediate: true }
);
</script>

<template>
	<div>
		<div class="section-title">
			<ASvg name="24/title-star" class="icon icon-star" />
			<ASvg name="24/title-star-blur" class="icon icon-star-blur" />

			<AText
				variant="teresina"
				as="span"
				gradient="var(--ghaziabad)"
				:modifiers="['bold', 'uppercase']"
				class="text-tlalnepantla"
				data-tid="promotions"
			>
				<span>{{ t("PROMOTIONS") }}</span>
			</AText>
		</div>
		<section class="content">
			<ASlider v-if="homePromotions?.promotions" ref="sliderRef" :options="sliderOptions" class="row-cards">
				<div
					v-for="(card, index) in homePromotions.promotions"
					:key="index"
					class="keen-slider__slide"
					data-tid="promo-card"
				>
					<OPromotionsCardSkeleton v-if="'skeleton' in card" />
					<template v-else>
						<AAnimationHoverSlide v-if="card.type === 'depositStreak'">
							<MPromotionDepositStreak
								type="depositStreak"
								:image="getImgFullPath(card.package?.imagePopup || '')"
								:deposit-info="depositInfoData"
								:finished-at="depositDurationLeft"
							/>
						</AAnimationHoverSlide>
						<AAnimationHoverSlide v-else-if="card.type === 'quest' && isFreeSpinQuest">
							<MPromotionFreeSpin type="quest" />
						</AAnimationHoverSlide>
						<AAnimationHoverSlide v-else-if="card.type === 'scratchCards'" :active="isDesktop">
							<MPromotionScratchCards :hideLabel="true" />
						</AAnimationHoverSlide>
						<AAnimationHoverSlide v-else-if="card.type === 'rankLeague'" :active="isDesktop">
							<MPromotionItem
								type="rankLeague"
								:showAvailable="false"
								:enabled="card.data?.enabled"
								:image="getImgFullPath(card.package?.imagePopup || '')"
								:title="card.package?.title || ''"
								:subTitle="card.package?.subTitle || ''"
								:coins="card?.data?.wheelPrize?.coins || 0"
								:entries="card?.data?.wheelPrize?.entries || 0"
								:bestDeal="false"
								:mostPopular="false"
								:vipClubEnabled="rankLeagueEnabled"
								:promotionInfo="true"
								:date="0"
								:imgWidth="135"
								:imgHeight="246"
								@click="handleVipClubClick"
							/>
						</AAnimationHoverSlide>
						<AAnimationHoverSlide v-else-if="card.type === 'tournament'" :active="isDesktop">
							<MPromotionRace
								type="tournament"
								:image="getImgFullPath(card.package?.imagePopup || '')"
								:title="card.package?.title || ''"
								:entries="entriesSum"
								:coins="coinsSum"
								:dateFinish="durationLeft"
								:isActive="activeStatus"
								:dateStart="tournamentData?.data?.start"
								:isSubscribed="isSubscribed"
								:subTitle="card.package?.subTitle || ''"
								@click="handleRaceClick"
							/>
						</AAnimationHoverSlide>
						<AAnimationHoverSlide v-else-if="card.type === 'rankLeagueReward'" :active="isDesktop">
							<MPromotionItem
								type="rankLeagueReward"
								:showAvailable="false"
								:vipSpinEnabled="luckySpinData?.wheelAvailable"
								:enabled="card.data?.enabled"
								:image="`/nuxt-img/vip/promo-card/wheel-${card?.data?.slug || rankStatusType}.png`"
								:title="card.package?.title || ''"
								:subTitle="card.package?.subTitle || ''"
								:coins="card?.data?.wheelPrize?.coins || 0"
								:entries="card?.data?.wheelPrize?.entries || 0"
								:bestDeal="false"
								:mostPopular="false"
								:promotionInfo="true"
								:date="0"
								:imgWidth="135"
								:imgHeight="246"
								@click="handleFortuneWheelClick"
							/>
						</AAnimationHoverSlide>
						<AAnimationHoverSlide v-else-if="card.type === 'chests' && !isActiveDailyLogin" :active="isDesktop">
							<MPromotionItem
								type="magicBox"
								:showAvailable="false"
								:enabled="true"
								:image="magicBoxPromotion?.[isSupercharged && !isEventBox ? 'imageSuperBox' : 'imagePopup'] || ''"
								:title="magicBoxPromotion?.title || ''"
								:subTitle="magicBoxPromotion?.[isActiveSuperchargedMode ? 'subTitleSuper' : 'subTitle'] || ''"
								:coins="card?.data?.grandPrize?.coins || 0"
								:entries="card?.data?.grandPrize?.entries || 0"
								currencySymbol="$"
								:date="0"
								:bestDeal="false"
								:mostPopular="false"
								:promotionInfo="true"
								:magicBoxAvailable="bonusesData?.isMagicBoxAvailable"
								:magicBoxDate="bonusesData?.dailyChests?.reasonValue || ''"
								:questSubscribed="!!questData?.data?.isSubscribed"
								:textTooltip="magicBoxPromotion.textTooltip"
								:imgWidth="270"
								:imgHeight="492"
								:superchargedMode="isSupercharged"
								:regularMode="isRegular"
								@click="handleMagicBoxClick"
							/>
						</AAnimationHoverSlide>
						<AAnimationHoverSlide v-else-if="card.type === 'quest'" :active="isDesktop">
							<MPromotionItem
								:type="card.type"
								:showAvailable="false"
								:enabled="card.data?.enabled"
								:image="getImgFullPath(card.package?.imagePopup || '')"
								:title="card.package?.title || ''"
								:subTitle="card.package?.subTitle || ''"
								:coins="currentTask?.prize?.coins || 0"
								:entries="currentTask?.prize?.entries || 0"
								:bestDeal="false"
								:mostPopular="false"
								currencySymbol="$"
								:questSubscribed="!!questData?.data?.isSubscribed"
								:date="0"
								:promotionInfo="true"
								:questTitle="currentTask?.title"
								:imgWidth="135"
								:imgHeight="246"
								@click="handleClick(card)"
							/>
						</AAnimationHoverSlide>
						<AAnimationHoverSlide v-else-if="card.type === 'season'" :active="isDesktop">
							<MPromotionSeason
								type="season"
								:image="getImgFullPath(card.package?.imagePopup || '')"
								:title="card.package?.title || ''"
								:subTitle="card.package?.subTitle || ''"
								:entries="card.package?.entries || 0"
								:dateFinish="seasonInitData?.finishedAt"
								:isAvailable="seasonInitData?.isAvailable"
								@click="handleSeasonClick"
							/>
						</AAnimationHoverSlide>
						<AAnimationHoverSlide v-else-if="card.type === 'dailyLogin' && isActiveDailyLogin" :active="isDesktop">
							<MPromotionDailyLogin
								type="dailyLogin"
								:durationLeft="durationLeftStreak"
								:isAvailable="isAvailable"
								:levels="levels"
								:activeLevel="activeLevel"
								:currentPrize="currentPrize"
								:totalPrizes="totalPrizes"
								:isCompleted="isCompleted"
								@click="open('LazyOModalDailyLogin')"
							/>
						</AAnimationHoverSlide>
						<AAnimationHoverSlide v-else-if="card.type === 'playerFreeSpin'" :active="isDesktop">
							<MPromotionDailyLogin
								type="playerFreeSpin"
								:gameId="card.data?.gameId"
								:durationLeft="durationLeftStreak"
								:isAvailable="isAvailable"
								:levels="levels"
								:activeLevel="activeLevel"
								:isCompleted="isCompleted"
								isPlayerFreeSpin
								@click="handleOpenFreeSpinGame(Number(card.data?.gameId ?? 0))"
							/>
						</AAnimationHoverSlide>
						<AAnimationHoverSlide v-else-if="card.type === 'prizeDrops' && prizeDropActive" :active="isDesktop">
							<MPromotionPrizeDrops
								:entries="prizeDropsData?.general?.dailyPrizePool || 0"
								:finished-at="prizeDropsData.general?.finishedAt || ''"
							/>
						</AAnimationHoverSlide>
						<AAnimationHoverSlide
							v-else-if="['offerWelcome', 'offerRegular'].includes(card.type || '')"
							:active="isDesktop"
						>
							<MPromotionBundle
								:type="card.type"
								:showAvailable="!!card.data?.promoOfferPreset?.availableTill"
								:enabled="card.data?.enabled"
								:image="getImgFullPath(card.package?.imagePopupMobile || '')"
								:coins="card.package?.coins || 0"
								:entries="card.package?.entries || 0"
								:date="card.date"
								@on-count-down-stop="handlePromotionsRefresh"
							/>
						</AAnimationHoverSlide>

						<AAnimationHoverSlide
							v-else-if="!['prizeDrops', 'dailyLogin', 'chests'].includes(card.type || '')"
							:active="isDesktop"
						>
							<MPromotionItem
								:type="card.type"
								:withDepositStreak="checkDepositStreakActive(Number(card.data?.money))"
								:showAvailable="!!card.data?.promoOfferPreset?.availableTill"
								:enabled="card.data?.enabled"
								:image="getImgFullPath(card.package?.imagePopup || '')"
								:title="card.package?.title || ''"
								:subTitle="card.package?.subTitle || ''"
								:coins="card.package?.coins || 0"
								:entries="card.package?.entries || 0"
								:badge="card.badgeLabel"
								:bestDeal="!!card.data?.promoOfferPreset?.bestDeal"
								:mostPopular="!!card.data?.promoOfferPreset?.mostPopular"
								:money="card.data?.money"
								currencySymbol="$"
								:textTooltip="card.tooltipText"
								:magicBoxAvailable="bonusesData?.isMagicBoxAvailable"
								:magicBoxDate="bonusesData?.dailyChests?.reasonValue || ''"
								:questSubscribed="!!questData?.data?.isSubscribed"
								:promotionInfo="card.hasPromotionInfo"
								:usualPrice="card.useualPrice"
								:date="card.date"
								:subType="card.data?.promoOfferPreset?.subType"
								:imgWidth="135"
								:imgHeight="246"
								@click="handleClick(card)"
								@on-count-down-stop="handlePromotionsRefresh"
							/>
						</AAnimationHoverSlide>
					</template>
				</div>
			</ASlider>
		</section>
		<div class="action">
			<AButton variant="primary" class="btn" size="xl" data-tid="promo-show-all" @click="goToPromotionPage">
				<AText variant="tanta" :modifiers="['semibold']" class="text-tlalnepantla">
					{{ t("Show All") }}
				</AText>
			</AButton>
		</div>
	</div>
</template>

<style scoped lang="scss">
:deep() {
	.keen-slider {
		margin-top: -20px;
		padding-top: 24px;

		.wrapper {
			width: 328px;
			height: 246px;
		}
	}

	.keen-slider__slide {
		padding: 0 8px;

		@include media-breakpoint-down(lg) {
			padding: 0 4px;
		}

		&:first-child {
			padding-left: 0;
		}
		&:last-child {
			padding-right: 0;
		}
	}
}

.section-title {
	width: 100%;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	align-items: center;
	margin-bottom: 32px;
	gap: 0 16px;
	position: relative;
	padding: 0 16px;

	@include media-breakpoint-down(md) {
		margin-bottom: 32px;
	}

	.icon-star {
		position: absolute;
		font-size: 50px;
		left: 14%;
		top: -20px;

		@include media-breakpoint-down(md) {
			font-size: 16px;
			left: -15px;
		}
	}
	.icon-star-blur {
		position: absolute;
		font-size: 100px;
		left: 5%;
		top: -10px;

		@include media-breakpoint-down(md) {
			font-size: 32px;
			left: 10px;
		}
	}
}

.content {
	display: flex;
	flex-direction: column;
	gap: 50px;
	width: 100%;
	margin: auto;
	z-index: 19;

	@include media-breakpoint-down(lg) {
		gap: 40px;
	}
}

.row-cards {
	display: flex;
	flex-wrap: wrap;

	@include media-breakpoint-down(lg) {
		justify-content: center;
	}

	* {
		box-sizing: border-box;
	}
}

.action {
	width: 100%;
	display: flex;
	justify-content: center;
	position: relative;
	margin-top: 32px;

	@include media-breakpoint-down(md) {
		margin-top: 24px;
	}
}

.btn {
	width: 280px;

	@include media-breakpoint-down(md) {
		width: 220px;
	}
}

.event-icon {
	position: absolute;

	&.right {
		width: 100px;
		height: 108px;
		right: 170px;
		bottom: 0;

		@include media-breakpoint-down(lg) {
			right: 20px;
		}

		@include media-breakpoint-down(md) {
			width: 50px;
			height: 54px;
		}
	}

	&.bottom {
		width: 115px;
		height: 93px;
		left: 170px;
		top: 450px;

		@include media-breakpoint-down(lg) {
			top: 380px;
			left: 20px;
		}

		@include media-breakpoint-down(md) {
			width: 57px;
			height: 47px;
		}
	}

	&.left {
		width: 61px;
		height: 60px;
		left: 130px;
		bottom: -15px;

		@include media-breakpoint-down(lg) {
			width: 31px;
			height: 30px;
			left: 0;
			bottom: 0;
		}
	}
}
</style>
